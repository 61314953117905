<template>
  <div class="zt-component-main">
    <div class="component-label" style="display: inline-block;">
      <slot></slot>
      {{ widget.componentName }}：
    </div>
    <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ computedCheck }}</div>
    <!--    <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ computedWidgetCustomContent }}</div>-->
  </div>
</template>

<script type="text/ecmascript-6">

import ComponentMixin from './component-mixin'
import {isEmpty}      from 'lodash'

export default {
  extends   : ComponentMixin,
  mixins    : [],
  components: {},
  name      : 'ZtPhone',
  props     : {
    className: { type: String, default: '' },
    widget   : { type: Object, required: true },
  },
  data() {
    return {}
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    computedCheck() {
      if (this.widget.answer) {
        let checkoutName = ''
        let arr          = this.widget.answer.split(',')
        for (let i = 0; i <= arr.length; i++) {
          let find = this.widget.optionList.find(item => {
            return item.optionValue == i
          })
          if (isEmpty(find)) {}
          else {
            checkoutName += find.optionName
          }
        }
        return checkoutName
      }
    },
  },
  methods : {
    //
  },
  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},
  mounted() {},
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "component.scss";
</style>
